import { Routes, Route } from "react-router-dom";
import { Home } from './Home';
import runOneSignal from "./OneSignal";
import { useEffect } from "react";

//window.OneSignal = window.OneSignal || [];

export function App() {
  useEffect(() => {
    runOneSignal();
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;